import React from 'react'
import PageLink from 'components/PageLink'
import { strings } from 'js/strings'

export default function TextCubes({ content, isCareers, addPlaceholder }) {
  const { title, body, pageLink, pageLinkText } = content
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip center-content-block'>
      <div className='hypercubes__base hypercubes__base-left'></div>
      <div className='hypercubes__base hypercubes__base-right'></div>
      <div className='center-content-block__wrap  center-content-block__wrap--left-align-mobile  '>
        <h2 className='center-content-block__title'>{title}</h2>
        <div
          className='center-content-block__excerpt'
          dangerouslySetInnerHTML={{ __html: body }}
        />
        {isCareers && (
          <a
            href='https://jobs.lever.co/analyticpartners'
            target='_blank'
            className='center-content-block__cta'
          >
            {strings.viewOpenPositions}
          </a>
        )}
        {pageLink && (
          <PageLink className='center-content-block__cta' pageLink={pageLink}>
            {pageLinkText}
          </PageLink>
        )}
      </div>
      {addPlaceholder && (
        <div className='center-content-block__flex'>
          <div className='center-content-block__partners__logo'>
            <img
              src=''
              alt=''
              className='center-content-block__partners__img'
            />
          </div>
        </div>
      )}
    </section>
  )
}
