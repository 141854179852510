import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import TextCubes from 'components/TextCubes'

export default function NotFoundPage({ data }) {
  const page = data.wpPage
  const { textCubes } = page.template.notFound
  return (
    <Layout
      bodyClassName='error404 app-data index-data 404-data four-oh-four'
      page={page}
    >
      <TextCubes content={textCubes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(slug: { eq: "404-not-found" }) {
      title
      template {
        templateName
        ... on WpTemplate_404 {
          notFound {
            textCubes {
              title
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
          }
        }
      }
    }
  }
`
